@font-face {
  font-family: "Luckiest Guy";
  src: url('../fonts/LuckiestGuy/LuckiestGuy-Regular.ttf') format('truetype');
}

@import url('https://fonts.googleapis.com/css2?family=Quicksand:wght@400;500;600&display=swap');

/* variables */
:root {
  /* font family*/
  --brand-primary-font: "Luckiest Guy";
  --brand-secondary-font:'Quicksand', sans-serif;
  /* font sizes*/
  /* headings */
--brand-heading-lg: 64px;
--brand-heading-md: 48px;
--brand-heading-sm: 28px;
--brand-heading-title: 24px;

/* responsive */
--brand-mb-heading-lg: 40px;
--brand-mb-heading-md: 24px;
--brand--mb-heading-title: 18px;
--brand-mb-text: 16px;

/* paragraph */
--brand-text: 16px;

/* links */
--brand-links: 16px;

/* background color */ 
--brand: #dedede;
--white: #fff;
--grey: #d9d9d9;
--pink-light: #FEF9F7;
--blue-dark: #363557;

/* section padding */
--padding-lg: 80px;
--padding-md: 50px;
--padding-sm: 20px;


}

body {
  font-family: var(--brand-secondary-font);
  font-size: var(--brand-text);
}

h1, h2 {
  font-family: var(--brand-primary-font)!important;
}

h3 {
  font-family: var(--brand-secondary-font)!important;
}

h4 {
  font-family: var(--brand-primary-font)!important;
}

p {
  font-family: var(--brand-secondary-font);
  font-size: var(--brand-text);
  line-height: 24px;
  color: #111111;
  font-weight: 400;
}


.bg-grey {
  background-color: var(--grey);
}

.bg-pink {
  background: var(--pink-light);
 
}

.bg-blue {
  background: var(--blue-dark);
}

.main {
  padding-top: var(--padding-lg);
  padding-bottom:var(--padding-lg);
}
.main-md {
  padding-top: var(--padding-md);
  padding-bottom:var(--padding-md);
}
.main-sm {
  padding-top: var(--padding-sm);
  padding-bottom:var(--padding-sm);
}

.btn.btn-brand {
  font-family: 'Quicksand';
font-style: normal;
font-weight: 400;
font-size: 16px;
line-height: 20px;
color: #F6F6F6;
  padding: 8px 24px;
  background: #ED673F;
  border-color: #ED673F;
  border-radius: 3px;
  text-transform: uppercase;
}

.btn.btn-brand-warning {
  background: #ED673F;
  color: #fff;
  padding: 6px 25px;
}

.btn-brand > img {
  margin-left: 8px;
}


/* .card {
  border: none;
}

.card-header {
  background: transparent;
  border-bottom: none;
} */

/* text styles */ 
.section-title {
  font-size: var(--brand-heading-title);
  color: #ED673F;
  font-weight: 500;
  line-height: 30px;
  text-transform: uppercase;
}
.section-heading {
  font-size: var(--brand-heading-lg);
}
.section-subheading {
  font-size: var(--brand-heading-md);
  line-height: 84px;
  color: #363557;
  font-weight: 700;
  /* font-size: 56px */
}

.card-title {
  font-family: var(--brand-secondary-font);
  font-size: var(--brand-heading-sm);
  color: #ED673F;
  font-weight: 700;
  font-size: 16px;
}

.card.custom-card {
  background: transparent;
  border: none;
}

a:hover {
  text-decoration: none;
}


/* navigation styles */

.custom-nav {
  /* position: absolute; */
  width: 100%;
  z-index: 100;
}

.custom-nav.with-banner-bg .navbar-light .navbar-nav .nav-link{
  color: #ED673F;
}

.custom-nav .navbar-light .navbar-nav .active>.nav-link,
.custom-nav .navbar-light .navbar-nav .nav-link {
  font-family: var(--brand-secondary-font);
  color: #fff;
  font-weight: 400;
font-size: 16px;

}
.custom-nav.with-banner-bg .navbar-light .navbar-nav .active>.nav-link,
.custom-nav.with-banner-bg .navbar-light .navbar-nav .nav-link {
  font-family: var(--brand-secondary-font);
  color: #ED673F;
  font-weight: 400;
font-size: 16px;

}

.custom-nav.with-banner-bg .navbar-brand img {
  width: 126px;
}

.custom-nav .navbar-expand-lg .navbar-nav .nav-link {
  padding-left: 30px!important;
  padding-right: 30px!important;
}

/* hero video */

.hero {
  background-image: linear-gradient(#e1e6e9 40%, #c9ccd1);
  position: relative;
  background-color: black;
  height: calc(100vh - 72px);
  min-height: 25rem;
  width: 100%;
  overflow: hidden;
}

.hero video {
  position: absolute;
  top: 50%;
  left: 50%;
  min-width: 100%;
  min-height: 100%;
  width: auto;
  height: auto;
  z-index: 0;
  -ms-transform: translateX(-50%) translateY(-50%);
  -moz-transform: translateX(-50%) translateY(-50%);
  -webkit-transform: translateX(-50%) translateY(-50%);
  transform: translateX(-50%) translateY(-50%);
}

.hero-content {
  position: relative;
  z-index: 2;
}

.hero-content h1 {
  font-size: var(--brand-heading-lg);
  color: #FAD1C5;
}

 .hero-content p {
  color: #fff;
}

.hero-overlay {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  background-color: #151712;
  opacity: 0.8;
  z-index: 1;
}

.hero-content .w-50 {
  padding: .5rem 1rem;
}

.hero-image-wrapper {
  position: relative;
}

.hero-image-wrapper::before, .hero-image-wrapper::after {
  content: '';
  position: absolute;
}

.hero-image-wrapper::before {
  background: url('/public/img/banner/banner-bg.png') no-repeat;
  background-size: contain;
  position: absolute;
  top: 0;
  left: 0;
  height: 200px;
  z-index: -1;
  width: 200px;
}
.hero-image-wrapper::after {
  background: url('/public/img/banner/banner-bg1.png') no-repeat;
  background-size: contain;
  position: absolute;
  bottom: 0;
  right: 0;
  height: 200px;
  z-index: -1;
  width: 200px;
}

.hero-image-wrapper .hero-content h1 {
  color: #363557;
}

.hero-image-wrapper .hero-content p{
  color: #323332;
}

/* about section */

.about-section .section-content {
  position: relative;
}

.about-section .section-content:before, .about-section .section-content:after {
  content: '';
  position: absolute;
}


.section-image-wrapper {
  position: relative;
}

.about-section .bone-1 {
  width: 24px;
}

.about-section .bone-2 {
  position: absolute;
  right: 0;
  width: 50px;
}

.about-section .bone-3 {
  position: absolute;
  bottom: -77%;
  right: 0;
  width: 24px;

}

.custom-card .card-header img {
  width: 56px;
}


.hi-img {
  position: absolute;
  top: -60px;
  left: -46px;
}

  .about-img {
    position: absolute;
    bottom: 0%;
    left: -24%;
    width: 150px;
}

/* services section */
.services-section .section-heading-content {
  position: relative;
}

.services-section .section-heading-content .paw-1, .services-section .section-heading-content .paw-2 {
  position: absolute;
}

.services-section .section-heading-content .paw-1.positioning{
  top: 20%;
    left: 5%;
}
.services-section .section-heading-content .paw-2.positioning{
  right: 5%;
  top: 45%;
  
}

.services-section .section-heading-content .paw-1 img, .services-section .section-heading-content .paw-2 img {
  width: 32px;
}

/* testimonial section */
.testimonial-section .section-heading-content .paw-1 img{
  width: 42px;
}
.testimonial-section .section-heading-content .paw-2 img{
  width: 84px;
}

.testimonial-section .paw-1.positioning {
  position: absolute;
  left: -20%;
  top: 10%;
}
.testimonial-section .paw-2.positioning {
  position: absolute;
  right: -14%;
    bottom: 20%;
}





/* cards */
.custom-card {
  margin-bottom: 40px;
  border: none;
}

.custom-card .card-header {
  background: transparent;
    border-bottom: none;
}

.custom-card .card-header h3{
  margin-top: 14px;
  margin-bottom: 0;
}
.custom-card .card-body {
  padding-top: 0;
}

/* gallery */
.gallery-wrapper {
  display: grid;
  gap: 15px;
}

.gallery-wrapper > div {
  background-color: #dedede;
  
}

.gallery-wrapper img {
  width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 8px;
}

.grid-1 {
  grid-template-columns: 2fr 1fr 1fr;
}

.grid-2 {
  grid-template-columns: 1fr 1fr 2fr;
}

.gallery-1-item:nth-child(1){
  grid-row: 1 / span 2;
}
.gallery-2-item:nth-child(3){
  /* grid-row: 3 / span 2; */
  grid-row-end: span 2;

}


/* .grid-2 {
  grid-template-columns: 1fr 1fr 2fr;
} */

.section-heading-content {
  position: relative;
}

.gallery-section .section-heading-content .bone-1 img{
  width: 50px;
}
.gallery-section .section-heading-content .bone-2 img{
  width: 42px;
}

.gallery-section .section-heading-content .bone-1 {
  position: absolute;
  top: 22%;
  left: -5%;
}

.gallery-section .section-heading-content .bone-2 {
  position: absolute;
  right: -5%;
}
.gallery-section {
  position: relative;
}
.gallery-section::after {
  content: '';
  background: url('/public/img/gallery/gallery3.png') no-repeat;
  background-size: contain;
  position: absolute;
    bottom: 5%;
    right: 4%;
    height: 38px;
    width: 38px;
    z-index: 0;
}

/* testimonial */

.testimonial-body {
  padding-top: 30px;
  padding-bottom: 140px;
}

.testimonial-section {
  position: relative;
  z-index: 2;

}

.testimonial-section:after {
  content: '';
  background: url('/public/img/circle-bg.png') no-repeat;
  background-size: contain;
  position: absolute;
  bottom: 0;
  left: 0;
  height: 600px;
  z-index: -1;
  width: 100%;
}

.testimonial-section:before {
  content: '';
  background-size: contain!important;
  position: absolute;
  background: url('/public/img/testimonial/paw-bg.png') no-repeat;
  bottom: 19%;
  right: 0;
  z-index: 1;
  height: 275px;
  width: 182px;
}

.img-circle {
  width: 90px;
  height: 90px;
  border: 2px solid red;
  border-radius: 50px;
  position: relative;
  overflow: hidden;
}

.img-circle img {
  object-fit: cover;
  width: 100%;
  height: 100%;
}

/* faq */


.faq-wrapper .accordion .card {
  border: none;
  border-bottom: 2px solid #E5E5E5;
  border-radius: 0;
}

.faq-wrapper .accordion .card-header {
  background: transparent;
  padding-bottom: 0;
  padding-left: 0;
  padding-right: 0;
  border: none;
}

.faq-wrapper .accordion .card-header h2 {
  font-weight: 500;
font-size: 24px;
color: rgba(0, 0, 0, 0.74);
position: relative;
padding: 0 0px;

}

.faq-wrapper .accordion .card-body {
  font-family: 'Quicksand';
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 36px;
  color: #545554;
}

.faq-wrapper .accordion .card-header .btn {
  font-family: 'Quicksand'!important;
    font-style: normal;
    font-weight: 600;
    font-size: 24px;
    line-height: 36px;
    color: #323332;
    padding-left: 0;
    padding-right: 30px;
    position: relative;
    z-index: 1;
    white-space: normal;
}

.faq-wrapper .accordion .card-header .btn:hover {
  text-decoration: none;
}

.faq-section .section-heading-content .paw-1 img {
  width: 74px;
}

.faq-section .section-heading-content .paw-1 {
  position: absolute;
  top: -24%;
    left: -10%;
}

.icon-wrapper {
  position: absolute;
  right: 0;
  top: 0;
  z-index: 0;
}
.icon-wrapper {
  width: 30px;
  height: 30px;
  border: 1px solid #ED673F;
  text-align: center;
  border-radius: 50px;
}

.icon-wrapper svg path {
  fill: #ED673F;
}

.custom-swiper-nav .swiper-button-prev, .custom-swiper-nav .swiper-button-next{
  width: 60px;
    height: 60px;
    background: #FFFFFF;
    box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.1);
    border-radius: 50px;
}

.custom-swiper-nav .swiper-button-next.swiper-button-disabled {
  background: #ED673F;
}

.custom-swiper-nav .swiper-button-next.swiper-button-disabled::after {
  color: #fff;
}

.custom-swiper-nav .swiper-button-prev {
  right: 88px!important;
    left: auto!important;
}

.custom-swiper-nav .swiper-button-prev, .custom-swiper-nav .swiper-button-next {
  top: 84%;
  transition: .3s all;
}

.custom-swiper-nav .swiper-button-prev:hover, .custom-swiper-nav .swiper-button-next:hover {
  background: #ED673F;
}

.custom-swiper-nav .swiper-button-prev:hover::after, .custom-swiper-nav .swiper-button-next:hover::after {
  color: #fff;
}

.custom-swiper-nav .swiper-button-next:after, .custom-swiper-nav .swiper-button-prev:after {
  font-size: 18px;
    font-weight: 600;
    color: #363557;
}

.custom-swiper-nav .swiper-button-next.swiper-button-disabled, .custom-swiper-nav .swiper-button-prev.swiper-button-disabled {
  opacity: 1;
}

.swiper-button-prev{
  left: auto;
  right: 100px;
}

.btn-link.focus, .btn-link:focus{
  text-decoration: none;
}

/* footer */

.footer .footer-header h3 {
  font-weight: 500;
font-size: 18px;
color: #ED673F;
text-transform: uppercase;
margin-bottom: 30px;
}

.footer .footer-body a , .footer .footer-body span, .footer .footer-body p{
  color: #F6F6F6;
  font-weight: 400;
font-size: 16px;
opacity: 0.75;
}

.footer .footer-body a:hover {
  text-decoration: none;
}

.footer .footer-body li {
  padding-bottom: 16px;
}

.footer {
  background:  #1B1B2C;
}

/* admin table */
.table-striped.admin-table tbody tr:nth-of-type(odd) {
  background: rgb(236 240 250 / 50%);
}
.table-striped.admin-table tbody tr:nth-of-type(even) {
  background: rgba(217, 217, 217, 0.1);
}

.table.admin-table thead th {
  border-bottom: none;
  border-top: none;
  font-family: 'Quicksand';
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 24px;
  color: #363557;
}

.table.admin-table thead tr {
  background: #FEF9F7;
    border-radius: 10px 10px 0px 0px;
}

.table.table.admin-table th, .table.table.admin-table td {
  font-family: var(--brand-secondary-font);
  border-top: none;
  text-transform: capitalize;

}

.table.table.admin-table td {
  border-bottom: 1px solid #E5E5E5;
}

.table.table.admin-table tbody td.username {
  color: #363557;
  font-size: 16px;
  font-weight: 500;
}
.table.table.admin-table tbody td.description {
  color: #323332;
  font-size: 14px;

}

.admin-title {
  font-family: var(--brand-secondary-font);
  color: #363557;
  font-weight: 700;
font-size: 32px;
}

.subnav-wrapper {
  padding: 20px 0;
}

.subnav-wrapper ul li a {
  color: #545554;
  font-weight: 600;
font-size: 18px;
}

.subnav-wrapper ul li.active a, .subnav-wrapper ul li a:hover, .subnav-wrapper ul li a:focus {
  color: #ED673F;
}

.subnav-wrapper ul li a:hover {
  text-decoration: none;
}

.admin-form form {
  padding-top: var(--padding-sm);
}
.admin-form form label, .contact-form form label {
  color: #363557;
    font-family: 'Quicksand';
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
}

.contact-form form label  {
  margin-bottom: 0;
}

.admin-form .form-control {
  background: #F6F6F6;
  border: 1px solid #D3D4D4;
}

.login-card {
    max-width: 400px;
    position: relative;
    overflow: hidden;
    padding: 20px;
    margin: 0 auto;
    background: #fff;
    box-shadow: 0px 15px 30px 15px rgb(217 217 217 / 25%);
        transition: all 300ms ease-in-out 0s;
}

  .slick-form .form-control {
    border-radius: 0;
    border: none;
    border-bottom: 2px solid #000;
    padding-left: 0;
}

.form-control::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
  font-family: 'Quicksand';
font-weight: 400;
font-size: 16px;
line-height: 24px;
  color: #545554!important;
  opacity: 1; /* Firefox */
}

.form-control:-ms-input-placeholder { /* Internet Explorer 10-11 */
  font-family: 'Quicksand';
font-weight: 400;
font-size: 16px;
line-height: 24px;
  color: #545554!important;
}

.form-control::-ms-input-placeholder { /* Microsoft Edge */
  font-family: 'Quicksand';
font-weight: 400;
font-size: 16px;
line-height: 24px;
  color: #545554!important;
}


.contact-page-form{
  background: #FFFFFF;
  box-shadow: 0px 0px 40px rgb(0 0 0 / 5%);
  border-radius: 10px;
  position: relative;
  overflow: hidden;
}

.form-left {
  padding: 42px 72px!important;
  background: var(--blue-dark);
}
.form-right {
  padding: 68px 72px!important;

}

.form-left {color: #fff;}
.form-left p{
  color: inherit;
}

.icon-circle {
  width: 38px;
  height: 38px;
  border-radius: 30px;
  border: 2px solid #fff;
  line-height: 29px;
  text-align:center;
}

.info {
  margin-left: 11px;
}

.contact-form .form-group {
  margin-bottom: 20px;
}

.contact-form .form-left h3, .contact-form .form-right h3 {
  font-family: var(--brand-primary-font)!important;
}

.contact-form .form-right h3 {
  color: #363557;

}

.slick-form .form-control:focus {
  box-shadow: none;
  border-color: #ED673F!important;
}
.social-icons li {
  margin-right: 15px;
}

.block-left h4, .block-right h4 {
  font-size: 24px;
  color: #ED673F!important;

}

.brand-card {
  padding: 0.75rem 1.25rem;
}



.brand-card {
  background: #FFFFFF;
  box-shadow: 0px 0px 40px rgba(0, 0, 0, 0.05);
border-radius: 15px;
border: none!important;
position: relative;
overflow: hidden;

}

.brand-card .card-header {
  background: #fff;
  border-bottom: none;
  border-radius: 10px;
    position: relative;
    overflow: hidden;
    height: 200px;
    max-height: 200px;
    padding: 0;
}

.brand-card .card-body  {
  padding-left: 0;
}

.brand-card .card-body h3 {
  color: #180A06;
  font-weight: 600;
font-size: 24px;
}

.divLink {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  text-decoration: none;
  z-index: 10;
  background-color: white;
  opacity: 0;
  filter: alpha(opacity=0);
}

.masorny-grid figure {
  margin: 0;
  display: grid;
  grid-template-rows: 1fr auto;
  margin-bottom: 10px;
  break-inside: avoid;
}

.masorny-grid figure > img {
  grid-row: 1 / -1;
  grid-column: 1;
}

.masorny-grid figure a {
  color: black;
  text-decoration: none;
}


.masorny-grid {
  column-count: 4;
  column-gap: 10px;
}

.masorny-grid figure {
  position: relative;
  cursor: pointer;
}

button.close-btn {
  width: 30px;
  height: 30px;
  border-radius: 30px;
  line-height: 16px;
  text-align: center;
  position: absolute;
  top: 0;
  right: 0;
  padding: 0;
}

/* responsive */

/* Extra small devices (phones, 600px and down) */
@media only screen and (max-width: 600px) {
  /* .example {background: red;} */
}

/* Small devices (portrait tablets and large phones, 600px and up) */
@media only screen and (max-width: 767.98px) {
  body {
    font-size: var(--brand-mb-text)!important;
    line-height: 21px;
  }

  .section-subheading {
    font-size: var(--brand-mb-heading-md);
    line-height: 36px;
  }
  
  .section-title {
    font-size: var(--brand--mb-heading-title);

  }

  .about-section  .section-content.positioning {

    padding-top: 60px;
  }

  .section-content.positioning {
    text-align: center;
}

  .btn.btn-brand {
    padding: 15px 24px;
  }

  .navbar-toggler {
    border-radius: 50px!important;
    width: 40px;
    height: 40px;
    line-height: 22px!important;
    text-align: center;
    padding: 0!important;
    background: #ED673F!important;
    color: #fff!important;
  }

  .hero-image-wrapper {
    overflow: hidden;
    padding-top: 78px;
  }

  .hero-image-wrapper::before {
  
    top: -74px;
    left: -85px;
    height: 200px;
    z-index: -1;
    width: 150px;
}

.hero-image-wrapper::after{
  right:-111px;
}

  .hero-content h1 {
    font-size: var(--brand-mb-heading-lg);
    line-height: 60px;
    text-align: center;
  }

  .hero-image-wrapper .hero-content p {
    text-align: center;
  }

  .hero-image-wrapper .hero-content .btn-more {
    text-align: center;
  }

  .section-image-wrapper {
    text-align: right;
  }

  .section-image-wrapper > img{
    height: 327px;
  }

  .about-img {
    bottom: -8%;
    left: 5%;
    width: 120px;
}



  .navbar-expand-lg .navbar-collapse {
   /* -webkit-box-shadow: 0px 10px 30px rgb(83 88 93 / 40%);
    box-shadow: 0px 10px 30px rgb(83 88 93 / 40%);
     position: absolute;
    left: 0;
    right: 0;
    top: 100%; */
    background: var(--white);
    border-top: 1px solid rgba(0, 0, 0, 0.1);
}

  .custom-nav .navbar-nav li.nav-item {
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
    padding: 8px 0px;
  }

  .custom-nav .navbar-expand-lg .navbar-collapse .navbar-nav .nav-item .nav-link {
    padding-left: 0px!important;
    padding-right: 10px!important;
  }

  .main {
    padding-top: var(--padding-md);
    padding-bottom:var(--padding-md);
  }

  .hero-content .w-50 {
    width: 80%!important;
  }

  .testimonial-section:after {
   
    height: 300px;
  
  }

  .hi-img {
    position: absolute;
    top: -50px;
    left: 30px;
  }

  .hi-img img {
    height: 150px;
  }

  .img-circle{
    width: 60px;
    height: 60px;
    margin-bottom: 24px;
  }

  .testimonial-section:after {
    display: none;
  }

  .testimonial-header {
    flex-direction: column;
}

.testimonial-wrapper .info-content h4 {
  font-family: 'Quicksand'!important;

  font-weight: 700;
font-size: 18px;
line-height: 27px;
color: #ED673F;
}

.testimonial-body {
  text-align: center;
  padding-bottom: 40px;
}

.info-content {
  text-align: center;
}

.swiper-button-prev {
  
  right: 64px!important;
  left: auto!important;
}

.custom-swiper-nav .swiper-button-prev, .custom-swiper-nav .swiper-button-next {
  width: 40px;
  height: 40px;
  
}

.custom-swiper-nav .swiper-button-prev, .custom-swiper-nav .swiper-button-next{
 
  top: 94%!important;
}

  .faq-wrapper .accordion .card-header .btn {
    padding-right: 32px;
  }

  .gallery-section::after {
    display: none;
  }

  .faq-wrapper .accordion .card-header .btn {
    font-size: 16px;
    line-height: 24px;
  }

  .faq-wrapper .accordion .card-body {
    font-size: 15px;
    line-height: 22px;

  }

  .footer {
    text-align: center;
  }

  .footer .footer-logo img {
    width: 155px;
    margin-bottom: 40px;
  }

  .other-pages .page-content img {
    margin-bottom:40px;
  }

  .other-pages .page-content p {
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
  }

  .block-left h4, .block-right h4 {
    margin-bottom: 16px;
  }

  .block-left h4, .block-left p, 
  .block-right h4, .block-right p {
    text-align: center;

  }

  .block-left img, .block-right img {
    margin-bottom: 24px;
  }

  .section-heading-content {
    margin-bottom: 40px;
  }

  .contact-page-form {
    border-radius: 0;
    box-shadow: none;
  }

  .form-left {
    padding: 42px 42px!important;
  }
  .form-right {
    padding: 42px 20px 0 20px!important;
}

.form-control::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
 
font-size: 16px;
line-height: 24px;

}

.form-control:-ms-input-placeholder { /* Internet Explorer 10-11 */

font-size: 16px;
line-height: 24px;

}

.form-control::-ms-input-placeholder { /* Microsoft Edge */
font-size: 16px;
line-height: 24px;

}
  
}


/* Large devices (laptops/desktops, 992px and up) */
@media only screen and (min-width: 992px) {
  .faq-wrapper .accordion .card-header .btn {
    padding-right: 32px;
}
} 

/* Extra large devices (large laptops and desktops, 1200px and up) */
@media only screen and (min-width: 1200px) {
 
}

